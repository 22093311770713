import { Component } from '@angular/core';
import { StorageService } from 'src/service/Storage.service';
import { RouterService } from "../../service/Router.service";
import { environment } from "../../environments/environment";
import { TYPE } from "../../environments/environment.type";
import { FormatAddress, FormatArea } from "../../util/google-map-address";

import SwiperCore, { SwiperOptions, Pagination } from "swiper";
import { RequestService } from "../../service/Request.service";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-order-detail',
  templateUrl: 'order-detail.page.html',
  styleUrls: ['order-detail.page.scss'],
})
export class OrderDetailPage {

  slide: SwiperOptions = {
    initialSlide: 0,
    speed: 400,
    autoHeight: true,
    pagination: true
  }

  rider = environment.type === TYPE.rider;

  data = null

  constructor(
    private storageService: StorageService,
    public requestService: RequestService,
    public routerService: RouterService,
    private route: ActivatedRoute
  ) {
    this.load();
  }

  load() {
    const id = this.route.snapshot.paramMap.get('id');
    this.requestService.order.find(id).subscribe((res) => {
      this.data = res.data;
    })
  }

  get mine() {
    return this.data?.accept_user_id === this.auth?.id || this.data?.publish_user_id === this.auth?.id;
  }

  get directionDistance() {
    return typeof (this.data.direction_distance) == "string" ? parseFloat(this.data.direction_distance) :
      typeof (this.data.direction_distance) == "number" ? this.data.direction_distance : 0;
  }

  get directionDistanceFixed() {
    return this.directionDistance.toFixed(2);
  }

  get departure_direction() {
    const address = this.data?.departure_direction_place?.address_components;
    if (!Array.isArray(address)) return null;
    return this.mine ? FormatAddress(address) : FormatArea(address);
  }

  get departure_direction_url() {
    if (!this.departure_direction) return null;
    return "https://maps.google.com/maps?q=" + this.departure_direction + "&ie=UTF8&output=embed";
  }

  get receiving_direction() {
    const address = this.data?.receiving_direction_place?.address_components;
    if (!Array.isArray(address)) return null;
    return this.mine ? FormatAddress(address) : FormatArea(address);
  }

  get receiving_direction_url() {
    if (!this.receiving_direction) return null;
    return "https://maps.google.com/maps?q=" + this.receiving_direction + "&ie=UTF8&output=embed";
  }

  get auth() {
    return this.storageService.auth;
  }

  get dateDeadline() {
    return this.data?.date_deadline ? moment(this.data.date_deadline).format("YYYY-MM-DD HH:mm:ss") : "未设置";
  }

  get weight() {
    return parseFloat(this.data?.weight || 0).toFixed(2);
  }

  get totalNumber() {
    return parseFloat(this.data?.total || 0);
  }

  get total() {
    return this.totalNumber.toFixed(2);
  }

  accept() {
    if (!this.auth) {
      this.routerService.navigateByUrl('/login').then(_ => {});
    } else {
      this.routerService.navigateForward(['/order', 'accept', this.data.id]).then(_ => {});
    }
  }

}
