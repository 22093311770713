import { Component } from '@angular/core';
import { StorageService } from '../../service/Storage.service';
import { RouterService } from "../../service/Router.service";
import { RequestService } from "../../service/Request.service";

@Component({
  selector: 'app-card-add',
  templateUrl: 'card-add.page.html',
  styleUrls: ['card-add.page.scss']
})
export class CardAddPage {

  constructor(
    public storageService: StorageService,
    public requestService: RequestService,
    public routerService: RouterService
  ) {}

  description: string = "";
  number: string = "";
  name: string = "";
  month: number;
  year: number;
  code: number;

  add() {
    this.requestService.bankCard.add({
      description: this.description,
      number: this.number,
      name: this.name,
      month: this.month,
      year: this.year,
      code: this.code,
    }).subscribe(() => {
      this.routerService.navigateBack('/card').then(_ => {});
    });
  }

}
