import {Component, OnInit, ViewChild} from '@angular/core';
import { StorageService } from '../../service/Storage.service';
import { RouterService } from "../../service/Router.service";
import {environment} from "../../environments/environment";
import {TYPE} from "../../environments/environment.type";
import _ from 'lodash';
import {RequestService} from "../../service/Request.service";

@Component({
  selector: 'app-user-detail',
  templateUrl: 'user-detail.page.html',
  styleUrls: ['user-detail.page.scss']
})
export class UserDetailPage implements OnInit {

  constructor(
    public storageService: StorageService,
    public requestService: RequestService,
    public routerService: RouterService
  ) {}

  rider = environment.type === TYPE.rider;

  get auth() {
    return this.storageService.auth;
  }

  get src() {
    return this.auth?.id ? this.requestService.image.url("user", this.auth.id) : undefined;
  }

  data: any = {};

  ngOnInit() {
    this.data = _.cloneDeep(this.auth);
  }

  @ViewChild('upload') upload;

  image = null;

  selectImage() {
    this.upload.nativeElement.click();
  }

  onChangeImage(files: FileList) {
    if (files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image = {
          file: file,
          image: reader.result
        };
      };
      reader.onerror = () => {
        this.image = null;
      };
      reader.onabort = () => {
        this.image = null;
      };
    } else {
      this.image = null;
    }
  }

  save() {
    this.requestService.base.info({
      name: this.data.name,
      description: this.data.description,
      tel: this.data.tel,
      email: this.data.email,
      id_card: this.data.id_card,
      driver_license: this.data.driver_license,
    }).subscribe((res) => {
      this.storageService.auth = res.info;
      if (this.image) {
        this.requestService.image.upload("/user/" + res.info.id, this.image.file).subscribe(() => {
          this.routerService.navigateBack('/home/mine').then(_ => {});
        })
      } else {
        this.routerService.navigateBack('/home/mine').then(_ => {});
      }
    })
  }

}
