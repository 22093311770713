import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ui-user-info',
  templateUrl: 'user-info.component.html',
  styleUrls: ['user-info.component.scss']
})
export class UserInfoComponent {

  @Input() src: string;

  @Input() name: string;

  @Input() description: string;

  @Input() hidden: boolean;

  @Input() audit: boolean | number;

  constructor() {}

}
