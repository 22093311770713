
export const FindAddress = (address, type, key = null, def = null) => {
  if (!Array.isArray(address)) return def;
  for (const i of address) {
    if (!Array.isArray(i.types)) continue;
    for (const x of i.types) {
      if (typeof (type) == "string" && x === type) return key ? i[key] : i;
      if (Array.isArray(type)) {
        for (const y of type) {
          if (x === y) return key ? i[key] : i;
        }
      }
    }
  }
  return def;
}

export const FormatAddress = (address) => {
  if (!Array.isArray(address)) return "";
  const space = " ";
  let result = "";
  const route = FindAddress(address, "route", "long_name", "");
  if (route) result += route;
  const street_number = FindAddress(address, "street_number", "long_name", "");
  if (street_number) result += (result ? "," + space : "") + street_number;
  const locality = FindAddress(address, ["locality"], "long_name", "");
  if (locality) result += (result ? space : "") + locality;
  const postal_code = FindAddress(address, ["postal_code"], "long_name", "");
  if (postal_code) result += (result ? space : "") + postal_code;
  const area = FindAddress(address, ["administrative_area_level_1"], "long_name", "");
  if (area) result += (result ? space : "") + area;
  const country = FindAddress(address, ["country"], "long_name", "");
  if (country) result += (result ? space : "") + country;
  return result;
}

export const FormatArea = (address) => {
  if (!Array.isArray(address)) return "";
  const space = " ";
  let result = "";
  const route = FindAddress(address, "route", "long_name", "");
  if (route) result += route;
  const locality = FindAddress(address, ["locality"], "long_name", "");
  if (locality) result += (result ? space : "") + locality;
  const postal_code = FindAddress(address, ["postal_code"], "long_name", "");
  if (postal_code) result += (result ? space : "") + postal_code;
  const area = FindAddress(address, ["administrative_area_level_1"], "long_name", "");
  if (area) result += (result ? space : "") + area;
  const country = FindAddress(address, ["country"], "long_name", "");
  if (country) result += (result ? space : "") + country;
  return result;
}
