import { Component } from '@angular/core';
import { StorageService } from 'src/service/Storage.service';
import { RouterService } from "../../service/Router.service";
import { environment } from "../../environments/environment";
import { TYPE } from "../../environments/environment.type";
import { FormatAddress, FormatArea } from "../../util/google-map-address";

import SwiperCore, { SwiperOptions, Pagination } from "swiper";
import { RequestService } from "../../service/Request.service";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import {MapSchedule} from "../../component/map-schedule/map-schedule";
import {ModalController} from "@ionic/angular";

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-order-schedule',
  templateUrl: 'order-schedule.page.html',
  styleUrls: ['order-schedule.page.scss'],
})
export class OrderSchedulePage {

  rider = environment.type === TYPE.rider;

  data;

  constructor(
    private storageService: StorageService,
    public requestService: RequestService,
    public routerService: RouterService,
    private route: ActivatedRoute,
    public modalController: ModalController
  ) {
    this.load();
  }

  load() {
    this.requestService.order.list({
      type: 10,
      status: 20
    }).subscribe((res) => {
      this.data = res.data;
    })
  }

  get auth() {
    return this.storageService.auth;
  }

  get has() {
    if (!this.data) return false;
    for (const i of this.data) {
      if (i.selected) return true;
    }
    return false;
  }

  getSelected() {
    const temp = [];
    for (const i of this.data) {
      if (i.selected) {
        temp.push(i);
      }
    }
    return temp;
  }

  select(i) {
    i.selected = !i.selected;
  }

  start() {
    MapSchedule.show(this.modalController, {
      order: this.getSelected()
    }).then(() => {});
  }

}
