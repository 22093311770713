import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { LangService } from './Lang.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    public loadingController: LoadingController,
    public alertController: AlertController,
    public lang: LangService
  ) {
  }

  async info(title: string, message: string) {
    const alert = await this.alertController.create({
      header: this.lang.get(title),
      message: this.lang.get(message),
      buttons: [this.lang.get('确定')]
    });
    await alert.present();
  }

  async loading() {
    const loading = await this.loadingController.create();
    await loading.present();
    return loading;
  }

  async closeLoading() {
    await this.loadingController.dismiss();
  }
}
