import { Pipe, PipeTransform } from '@angular/core';
import { StorageStatic } from '../service/Storage.static';

@Pipe({
  name: 'Language'
})
export class LanguagePipe implements PipeTransform {
  transform(data: any, key?: string): string {
    if (!data) { return ''; }
    if (typeof (data) === 'string') { return data; }
    if (typeof (data) === 'object') {
      if (!key) { key = 'name'; }
      // 多语言
      if (typeof (data[key + '_language']) === 'object') {
        const lang = StorageStatic.language;
        const temp = data[key + '_language'][lang];
        if (typeof (temp) === 'string' && temp && temp.trim().length !== 0) {
          return temp;
        }
      }
      // 默认
      if (typeof (data[key]) === 'string') { return data[key]; }
    }
    return '';
  }
}
