import { NgModule } from '@angular/core';
import { LanguagePipe } from './language.pipe';
import { DatePipe } from '@angular/common';
import { SafePipe } from "./safe.pipe";

@NgModule({
  imports: [
  ],
  declarations: [
    SafePipe,
    LanguagePipe
  ],
  exports: [
    SafePipe,
    LanguagePipe
  ],
  providers: [
    DatePipe,
    SafePipe,
    LanguagePipe
  ]
})
export class PipeModule {}
