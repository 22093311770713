import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './Storage.service';
import { LANGUAGE } from './Storage.static';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  constructor(
    private translate: TranslateService,
    public storage: StorageService
  ) { }

  static get languages() {
    return LANGUAGE;
  }

  get languages() {
    return LANGUAGE;
  }

  get lang(): string {
    return this.translate.currentLang;
  }

  get(key: string | string[], params?: Record<string, any>) {
    if (!key) { return ''; }
    let temp = Array.isArray(key) ? key.join('') : key;
    this.translate.get(key, params).subscribe((value) => {
      temp = value;
    });
    return temp;
  }
}
