import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import $ from 'jquery';

@Directive({
  selector: '[shadow]'
})
export class IonContentHideShadow implements OnInit {

  constructor(
    private el: ElementRef
  ) { }

  @Input('shadow')
  shadow: boolean = true;

  wait(fn: Function, loop: number) {
    if (loop > 10) return;
    setTimeout(() => {
      if (!this.shadow) {
        const root = this.el.nativeElement.shadowRoot;
        if (root && root.childNodes && root.childNodes.length) {
          fn(root);
        } else {
          this.wait(fn, loop + 1);
        }
      }
    }, 50);
  }

  ngOnInit() {
    this.wait((root) => {
      $(root).find(".transition-effect").css("visibility", "hidden");
    }, 0);
  }

}


@Directive({
  selector: '[scroll]'
})
export class IonContentScrollStyle implements OnInit {

  constructor(
    private el: ElementRef
  ) { }

  @Input('scroll')
  scroll: boolean = true;

  wait(fn: Function, loop: number) {
    if (loop > 10) return;
    setTimeout(() => {
      const root = this.el.nativeElement.shadowRoot;
      if (root && root.childNodes && root.childNodes.length) {
        fn(root);
      } else {
        this.wait(fn, loop + 1);
      }
    }, 50);
  }

  ngOnInit() {
    this.wait((root) => {
      $(root).append(`
        <style>
          .inner-scroll::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            background-color: transparent;
          }
          .inner-scroll::-webkit-scrollbar-track {
            background-color: transparent;
          }
          .inner-scroll::-webkit-scrollbar-thumb {
            background-color: #DDD;
          }
          .inner-scroll::-webkit-scrollbar-button {
            display: none;
          }
        </style>
      `);
    }, 0);
  }

}




