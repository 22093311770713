import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import md5 from 'md5';
import { Observable } from 'rxjs';
import moment from 'moment';

import { StorageService } from './Storage.service';
import { AlertService } from './Alert.service';
import { RouterService } from './Router.service';

import { base64Encode } from '../util/base64';

import { environment } from '../environments/environment';
import { TYPE } from '../environments/environment.type';
import { GetDistance } from "../util/geolocation-distance";

export type Result = {
  status: null|number;
  error?: string;
};

export type UserInfo = {
  id: string|number;
  date_create: string | null;
  date_login: string | null;
  date_modify: string | null;
  description: string;
  name: string;
  type: number;
  username: string;
  token: string;
};

export type UserInfoParams = {
  id_card?: string;
  driver_license?: string;
  name?: string;
  description?: string;
  tel_country?: string;
  tel?: string;
  email?: string;
};

/*
// 单接口
this.requestService.base.login({
  username: this.username,
  password: this.password
}).subscribe((res) => {
  console.log(res)
})
// 多接口
forkJoin([
  this.requestService.base.login({
    username: this.username,
    password: this.password
  }),
  this.requestService.base.login({
    username: this.username,
    password: this.password
  })
]).subscribe((res) => {
  console.log(res)
})
*/

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(
    private storageService: StorageService,
    public alertService: AlertService,
    public routerService: RouterService,
    private http: HttpClient
  ) {}

  private static authorization(...args: string[]) {
    return 'Basic ' + md5(base64Encode(args.join('.')));
  }

  private static concatUrl(...urls: string[]) {
    let url = '';
    for (const u of urls) {
      const utemp = u + '';
      if (utemp.indexOf('http:') === 0 || utemp.indexOf('https:') === 0 || utemp.indexOf('file:') === 0) {
        url = utemp;
        continue;
      }
      if (url.lastIndexOf('/') === url.length - 1) {url = url.substring(0, url.length - 1);}
      if (utemp.indexOf('/') === 0) {
        url += utemp;
      } else {
        url += '/' + utemp;
      }
    }
    return url;
  }

  private static url(...urls: string[]) {
    const base = environment.production ? environment.server.api : 'http://localhost:8002';
    return RequestService.concatUrl.apply(null, [base, ...urls]);
  }

  private headers(data?: Record<string, string>, options?: {
    auth: boolean;
  }) {
    const auth = this.storageService.auth;
    const headers: Record<string, string> = { ...(data || {}) };
    headers['app-key'] = environment.appkey;
    headers['app-id'] = environment.appid;
    headers.uuid = this.storageService.uuid;
    headers.type = environment.type === TYPE.merchant ? '10' : '20';
    if (options?.auth && auth) {headers.authorization = RequestService.authorization(auth.username, auth.token);}
    return new HttpHeaders(headers);
  }

  empty(): Observable<any> {
    return new Observable((subscriber) => {
      subscriber.complete();
    });
  }

  private subscribe(options, subscriber, res) {
    if (res.status === 405) {
      this.storageService.auth = null;
      this.routerService.navigateBack('/', { replaceUrl: true }).then(_ => {});
      this.alertService.info('提示', '其他地方登录').then(_ => {});
    } else if (options.error && res.status !== 200) {
      this.alertService.info('提示', res.error || '未知错误').then(_ => {});
    } else {
      subscriber.next(res);
    }
    subscriber.complete();
  }

  private static filter(data: Record<string, any>) {
    const result: Record<string, any> = {};
    for (const i in data) {
      if (data.hasOwnProperty(i) && data[i] !== undefined) {result[i] = data[i];}
    }
    return result;
  }

  get(options: {
    url: string;
    params?: Record<string, any>;
    auth?: boolean;
    error?: boolean;
    loading?: boolean;
  }): Observable<Result & any> {
    return new Observable<Result & any>((subscriber) => {
      (async () => {
        let loading = null;
        if (options.loading) { loading = await this.alertService.loading(); }
        this.http.get<Result>(RequestService.url(options.url), {
          params: options.params ? RequestService.filter(options.params) : undefined,
          headers: this.headers(null, {
            auth: options.auth
          })
        }).subscribe((res) => {
          this.subscribe(options, subscriber, res);
          if (options.loading) { loading.dismiss(); }
        });
      })();
    });
  }

  post(options: {
    url: string;
    params?: Record<string, any>;
    data?: any;
    auth?: boolean;
    error?: boolean;
    loading?: boolean;
  }): Observable<Result & any> {
    return new Observable<Result & any>((subscriber) => {
      (async () => {
        let loading = null;
        if (options.loading) { loading = await this.alertService.loading(); }
        this.http.post<Result>(RequestService.url(options.url), options.data, {
          params: options.params ? RequestService.filter(options.params) : undefined,
          headers: this.headers(null, {
            auth: options.auth
          })
        }).subscribe((res) => {
          this.subscribe(options, subscriber, res);
          if (options.loading) { loading.dismiss(); }
        });
      })();
    });
  }

  patch(options: {
    url: string;
    params?: Record<string, any>;
    data?: any;
    auth?: boolean;
    error?: boolean;
  }): Observable<Result & any> {
    return new Observable<Result & any>((subscriber) => {
      this.http.patch<Result>(RequestService.url(options.url), options.data, {
        params: options.params ? RequestService.filter(options.params) : undefined,
        headers: this.headers(null, {
          auth: options.auth
        })
      }).subscribe((res) => {
        this.subscribe(options, subscriber, res);
      });
    });
  }

  put(options: {
    url: string;
    params?: Record<string, any>;
    data?: any;
    auth?: boolean;
    error?: boolean;
  }): Observable<Result & any> {
    return new Observable<Result & any>((subscriber) => {
      this.http.put<Result>(RequestService.url(options.url), options.data, {
        params: options.params ? RequestService.filter(options.params) : undefined,
        headers: this.headers(null, {
          auth: options.auth
        })
      }).subscribe((res) => {
        this.subscribe(options, subscriber, res);
      });
    });
  }

  delete(options: {
    url: string;
    params?: Record<string, any>;
    data?: any;
    auth?: boolean;
    error?: boolean;
  }): Observable<Result & any> {
    return new Observable<Result & any>((subscriber) => {
      this.http.delete<Result>(RequestService.url(options.url), {
        params: options.params ? RequestService.filter(options.params) : undefined,
        body: options.data,
        headers: this.headers(null, {
          auth: options.auth
        })
      }).subscribe((res) => {
        this.subscribe(options, subscriber, res);
      });
    });
  }

  base = {
    /**
     * 登录
     */
    login: (data: {
      tel_country?: string;
      username: string;
      password: string;
    }): Observable<Result & {
      info: UserInfo;
    }> => {
      if (data.username.length < 2) {
        this.alertService.info('提示', '请正确输入用户名').then(_ => {});
        return this.empty();
      }
      if (data.password.length < 2) {
        this.alertService.info('提示', '请正确输入密码').then(_ => {});
        return this.empty();
      }
      return this.post({
        url: '/user/login',
        data,
        error: true
      });
    },
    /**
     * 验证登录
     */
    check: (): Observable<Result & {
      info: UserInfo;
    }> => {
      if (!this.storageService.auth) {return this.empty();}
      return this.get({
        url: '/user/check',
        auth: true,
        error: true
      });
    },
    /**
     * 注册
     */
    register: (data: UserInfoParams & {
      username?: string;
      password: string;
      license_plate?: string;
      load_weight?: number;
      seat?: number;
      verify?: string;
    }): Observable<Result & {
      info: UserInfo;
    }> => {
      if (!data.username?.length && data.tel) {
        data.username = data.tel_country + data.tel;
      }
      if (data.username?.length < 2) {
        this.alertService.info('提示', '请正确输入用户名').then(_ => {});
        return this.empty();
      }
      if (data.password.length < 2) {
        this.alertService.info('提示', '请正确输入密码').then(_ => {});
        return this.empty();
      }
      return this.post({
        url: '/user/register',
        data: {
          verify: data.verify,
          user: {
            username: data.username,
            password: data.password,
            type: environment.type === TYPE.merchant ? 10 : 20,
            name: data.name,
            description: data.description,
            tel_country: data.tel_country,
            tel: data.tel,
            email: data.email,
            id_card: data.id_card,
            driver_license: data.driver_license
          },
          car: {
            license_plate: data.license_plate,
            load_weight: data.load_weight,
            seat: data.seat
          }
        },
        error: true
      });
    },
    /**
     * 修改用户信息
     */
    info: (data: UserInfoParams): Observable<Result & {
      info: UserInfo;
    }> => this.post({
        url: '/user/info',
        data: {
          data: {
            name: data.name,
            description: data.description,
            tel: data.tel,
            email: data.email,
            id_card: data.id_card,
            driver_license: data.driver_license
          }
        },
        auth: true,
        error: true
      }),
  };

  design = {
    list: (params: {
      display?: string | number;
      type?: string | number;
    }) => this.get({
        url: '/design',
        params,
        error: true
      })
  };

  pay(data: {
    order_id: string | number;
    card_id: string | number;
    deposit?: boolean;
    commission?: boolean;
    total: number;
  }) {
    return this.post({
      url: '/pay',
      data: {
        data
      },
      auth: true,
      error: true,
      loading: true
    });
  };

  order = {
    list: (params: {
      publish_user_id?: string | number;
      accept_user_id?: string | number;
      code?: string;
      type?: string | number;
      status?: string | number;
      payment?: string | number;
      search?: string;
    }) => this.get({
        url: '/order',
        params,
        auth: true,
        error: true
      }),
    find: (id: string | number) => {
      if (!id) {return this.empty();}
      return this.get({
        url: '/order/' + id,
        auth: true,
        error: true
      });
    },
    /**
     * 发布订单
     */
    publish: (data: {
      title: string;
      remark: string;
      total: number;
      weight: number;
      date_deadline: string | Date;
      departure_direction: string;
      departure_direction_place: any;
      receiving_direction: string;
      receiving_direction_place: any;
      direction_distance?: number;
    }, options?: {
      departure_direction?: boolean;
      receiving_direction?: boolean;
      total?: boolean;
      weight?: boolean;
    }) => {
      if (data.title.length < 2) {
        this.alertService.info('提示', '请正确输入标题').then(_ => {});
        return this.empty();
      }
      if (data.remark.length < 2) {
        this.alertService.info('提示', '请正确输入备注').then(_ => {});
        return this.empty();
      }
      if (options?.total && (!data.total || data.total < 0)) {
        this.alertService.info('提示', '请正确输入价格').then(_ => {});
        return this.empty();
      }
      if (options?.weight && (!data.weight || data.weight < 0)) {
        this.alertService.info('提示', '请正确输入重量').then(_ => {});
        return this.empty();
      }
      if (options?.departure_direction && (data.departure_direction.length < 2 || !data.departure_direction_place)) {
        this.alertService.info('提示', '请选择发货地址').then(_ => {});
        return this.empty();
      }
      if (options?.receiving_direction && (data.receiving_direction.length < 2 || !data.receiving_direction_place)) {
        this.alertService.info('提示', '请选择收货地址').then(_ => {});
        return this.empty();
      }
      if (data.date_deadline) {
        data.date_deadline = moment(data.date_deadline).format('YYYY-MM-DD HH:mm:ss');
      }
      if (data.departure_direction_place && data.receiving_direction_place) {
        data.direction_distance = GetDistance(data?.departure_direction_place?.geometry?.location,
          data?.receiving_direction_place?.geometry?.location);
      }
      return this.post({
        url: '/order/publish',
        data: {
          data
        },
        auth: true,
        error: true
      });
    },
    /**
     * 确认订单
     */
    accept: (data: {
      id: string | number;
    }) => this.post({
        url: '/order/accept',
        data: {
          data
        },
        auth: true,
        error: true
      }),
    /**
     * 修改订单信息
     */
    modify: (data: {
      id: string | number;
      type?: string | number;
      status?: string | number;
      payment?: string | number;
      images?: number[];
    }): Observable<Result & {
      info: UserInfo;
    }> => this.patch({
        url: '/order',
        data: {
          data
        },
        auth: true,
        error: true
      }),
  };

  car = {
    list: () => this.get({
        url: '/user-car',
        auth: true,
        error: true
      }),
    add: (data: {
      name: string;
      description: string;
      license_plate: string;
      load_weight: number;
      seat: number;
    }) => this.post({
        url: '/user-car',
        data: {
          data
        },
        auth: true,
        error: true
      }),
    remove: (id: string|number) => this.delete({
        url: '/user-car',
        data: {
          data: {
            id
          }
        },
        auth: true,
        error: true
      })
  };

  bankCard = {
    list: () => this.get({
        url: '/user-bank-card',
        auth: true,
        error: true
      }),
    add: (data: {
      description: string;
      number: string;
      name: string;
      month: number;
      year: number;
      code: number;
    }) => this.post({
        url: '/user-bank-card',
        data: {
          data
        },
        auth: true,
        error: true
      }),
    remove: (id: string|number) => this.delete({
        url: '/user-bank-card',
        data: {
          data: {
            id
          }
        },
        auth: true,
        error: true
      })
  };

  image = {
    url: (...urls: string[]) => RequestService.url.apply(null, ['/image', ...urls]),
    upload: (url: string, file: File) => {
      const data = new FormData();
      data.set('file', file);
      return this.post({
        url: RequestService.concatUrl('/image', url),
        data,
        auth: true
      });
    }
  };

  sms = {
    receive: (phone: string) => {
      if (phone.length < 2) {
        this.alertService.info('提示', '请正确输入电话号码').then(_ => {});
        return this.empty();
      }
      return this.post({
        url: '/sms',
        data: {
          phone
        },
        error: true
      });
    }
  };



  setting() {
    return this.get({
      url: '/setting',
      auth: true
    });
  };

}
