
const calculate = (lat1, lng1, lat2, lng2) => {
  const radLat1 = lat1 * Math.PI / 180.0;
  const radLat2 = lat2 * Math.PI / 180.0;
  const a = radLat1 - radLat2;
  const  b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
  let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b/2),2)));
  s = s * 6378.137;
  s = Math.round(s * 10000) / 10000;
  return s;
}

export const GetDistance = (arg1, arg2, arg3 = null, arg4 = null) => {
  if (typeof (arg1) == "object" && typeof (arg2) == "object") {
    return calculate(arg1.lat, arg1.lng, arg2.lat, arg2.lng);
  } else if (typeof (arg1) == "number" && typeof (arg2) == "number" && typeof (arg3) == "number" && typeof (arg4) == "number") {
    return calculate(arg1, arg2, arg3, arg4);
  }
  return 0;
}
