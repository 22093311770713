export function base64Encode(input: string): string{
    let rv = "";
    try {
        rv = encodeURIComponent(input);
        rv = unescape(rv);
        rv = window.btoa(rv);
    } catch(ignore) {}
    return rv;
}

export function base64Decode(input: string): string{
    let rv = "";
    try {
        rv = window.atob(input);
        rv = escape(rv);
        rv = decodeURIComponent(rv);
    } catch(ignore) {}
    return rv;
}