import { Component, ElementRef, ViewChild, Input } from '@angular/core';
import { StorageService } from '../../service/Storage.service';
import { RouterService } from '../../service/Router.service';
import { RequestService } from '../../service/Request.service';
import { AlertService } from '../../service/Alert.service';
import { LangService } from '../../service/Lang.service';
import { ModalController } from '@ionic/angular';

// const baseUrl = 'https://merchant.pmulogistics.com/assets/google-map-schedule.html';
const baseUrl = 'assets/google-map-schedule.html';

@Component({
  selector: 'app-map-schedule',
  templateUrl: 'map-schedule.html',
  styleUrls: ['map-schedule.scss']
})
export class MapSchedule {

  setting = null;
  contentWindow = null;
  direction = null;

  constructor(
    public storageService: StorageService,
    public requestService: RequestService,
    public alertService: AlertService,
    public lang: LangService,
    public routerService: RouterService,
    public modalController: ModalController
  ) {}

  static async show(modalController: ModalController, props: {
    order: Array<any>;
  }) {
    const modal = await modalController.create({
      component: MapSchedule,
      cssClass: 'app-modal-map-schedule',
      componentProps: props
    });
    return await modal.present();
  }

  @Input() order: Array<any>;

  async close() {
    await this.modalController.dismiss();
  }

  @ViewChild('iframe') iframe: ElementRef<HTMLInputElement>;

  sendData(type, data) {
    this.contentWindow.postMessage({ type, data }, '*');
  }

  message(event) {
    if (event.data?.id !== 'map-schedule') return;
    if (event.data?.type === 'log') {
      console.log(event.data?.data);
    } else if (event.data?.type === 'init') {
      this.contentWindow = event.source;
      this.sendData('init', { key: this.setting['google-map-api-key'], language: this.storageService.language, order: this.order });
    } else if (event.data?.type === 'error') {
      const e = event.data?.data;
      if (e.code === 1) {
        this.alertService.info('提示', this.lang.get(e.message) + ': ' + e.data).then(_ => {});
      }
    } else if (event.data?.type === 'language') {
      const data = event.data?.data;
      const temp = {};
      for (const i in data) {
        if (data.hasOwnProperty(i)) {
          temp[i] = this.lang.get(data[i]);
        }
      }
      this.sendData('language', temp);
    }
  }

  messageHandle = null;

  ionViewDidEnter() {
    this.messageHandle = this.message.bind(this);
    window.addEventListener('message', this.messageHandle, false);
    // 加载配置
    this.requestService.setting().subscribe((res) => {
      this.setting = res.data;
      this.iframe.nativeElement.src = baseUrl;
    });
  }

  ionViewDidLeave() {
    window.removeEventListener('message', this.messageHandle, false);
  }
}
