import {Component, OnDestroy, Input, OnInit} from '@angular/core';
import { StorageService } from 'src/service/Storage.service';
import { RouterService } from '../../service/Router.service';
import { RequestService } from '../../service/Request.service';
import { NavigationEnd, Router } from '@angular/router';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-card',
  templateUrl: 'card.page.html',
  styleUrls: ['card.page.scss'],
})
export class CardPage implements OnDestroy, OnInit {

  list = null;

  subscrition = null;

  constructor(
    private modalController: ModalController,
    public requestService: RequestService,
    private storageService: StorageService,
    public routerService: RouterService,
    private router: Router
  ) {
    this.subscrition = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd && e.url == '/card') {
        this.load();
      }
    });
  }

  static async show(modalController: ModalController, onSelect: any) {
    const modal = await modalController.create({
      component: CardPage,
      cssClass: 'app-modal-card-select',
      componentProps: {
        modal: true,
        onSelect
      }
    });
    return await modal.present();
  }

  ngOnInit() {
    if (this.modal) {
      this.load();
    }
  }

  @Input()
  modal = false;

  @Input()
  onSelect;

  async close() {
    await this.modalController.dismiss();
  }

  ngOnDestroy() {
    this.subscrition.unsubscribe();
  }

  setSelect(i) {
    if (this.onSelect) { this.onSelect(i); }
    this.close();
  }

  load() {
    this.requestService.bankCard.list().subscribe((res) => {
      this.list = res.data;
    });
  }

  add() {
    this.routerService.navigateByUrl('/card/add').then(_ => {});
  }

  remove(id) {
    this.requestService.bankCard.remove(id).subscribe((res) => {
      this.load();
    });
  }

}
