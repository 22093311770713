import {Component, HostListener, Input, Output, EventEmitter, ViewChild} from '@angular/core';

@Component({
  selector: 'app-ui-upload',
  templateUrl: 'upload.component.html',
  styleUrls: ['upload.component.scss']
})
export class UploadComponent {

  constructor() {}

  data = null;

  @Output()
  change = new EventEmitter();

  @ViewChild('upload') upload;

  parse(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.data = {
        el: this,
        input: this.upload,
        file: file,
        image: reader.result
      };
      this.change.emit(this.data);
    };
  }

  file(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      if (files[i]) this.parse(files[i]);
    }
  }

  @HostListener("click")
  onClick() {
    this.upload.nativeElement.click();
  }

}
