import { Component } from '@angular/core';
import { StorageService } from '../../service/Storage.service';
import { RequestService } from "../../service/Request.service";
import { RouterService } from "../../service/Router.service";
import { AlertService } from "../../service/Alert.service";
import { environment } from "../../environments/environment";
import { TYPE } from "../../environments/environment.type";

@Component({
  selector: 'app-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss']
})
export class LoginPage {

  rider = environment.type === TYPE.rider;

  tel_country: string = "+34";
  username: string = "";
  password: string = "";

  constructor(
    public requestService: RequestService,
    public storageService: StorageService,
    public routerService: RouterService,
    public alertService: AlertService
  ) {}

  get name() {
    return "PMULOGISTICS";
  }

  register() {
    this.routerService.navigateByUrl("/register").then(_ => { });
  }

  async login() {
    this.requestService.base.login({
      tel_country: this.tel_country,
      username: this.username,
      password: this.password
    }).subscribe(async (res) => {
      this.storageService.auth = res.info;
      this.routerService.navigateBack("/home/mine", { replaceUrl: true }).then(_ => {});
    });
  }

}
