import { Component } from '@angular/core';
import { StorageService } from 'src/service/Storage.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-about',
  templateUrl: 'about.page.html',
  styleUrls: ['about.page.scss'],
})
export class AboutPage {

  constructor(
    private storage: StorageService,
    private platform: Platform
  ) {}

  get ios() {
    return this.platform.is('capacitor') && this.platform.is('ios');
  }

  get android() {
    return this.platform.is('capacitor') && this.platform.is('android');
  }

  get version() {
    return "1.0.0";
  }

  get name() {
    return "PMULOGISTICS";
  }

}
