import { Injectable } from '@angular/core';
import { NavigationBehaviorOptions, NavigationExtras, Router, UrlTree } from '@angular/router';
import { StorageService } from './Storage.service';
import { NavController } from '@ionic/angular';
import { AnimationOptions, NavigationOptions } from '@ionic/angular/providers/nav-controller';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  constructor(
    private storage: StorageService,
    private nav: NavController,
    public router: Router
  ) { }

  get url() {
    return this.router.url;
  }

  get events() {
    return this.router.events;
  }

  routerLink(url: any[]): any[] {
    return url;
  }

  navigate(url: any[], extra?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(this.routerLink(url), extra);
  }

  routerLinkByUrl(url: string): string {
    if (url.indexOf('http') === 0) {
      return url;
    }
    return url;
  }

  navigateByUrl(url: string | UrlTree, extras?: NavigationBehaviorOptions): Promise<boolean> {
    if (typeof(url) === 'string') {
      if (url.indexOf('http') === 0) {
        location.href = url;
        return;
      }
      return this.router.navigateByUrl(this.routerLinkByUrl(url), extras);
    }
    return this.router.navigateByUrl(url, extras);
  }

  navigateBack(url: string | UrlTree | any[], options?: NavigationOptions): Promise<boolean> {
    if (typeof(url) === 'string') {
      if (url.indexOf('http') === 0) {
        location.href = url;
        return;
      }
      return this.nav.navigateBack(this.routerLinkByUrl(url), options);
    } else if (Array.isArray(url)) {
      return this.nav.navigateBack(this.routerLink(url), options);
    }
    return this.nav.navigateBack(url, options);
  }

  navigateForward(url: string | UrlTree | any[], options?: NavigationOptions): Promise<boolean> {
    if (typeof(url) === 'string') {
      if (url.indexOf('http') === 0) {
        location.href = url;
        return;
      }
      return this.nav.navigateForward(this.routerLinkByUrl(url), options);
    } else if (Array.isArray(url)) {
      return this.nav.navigateForward(this.routerLink(url), options);
    }
    return this.nav.navigateForward(url, options);
  }

  navigateRoot(url: string | UrlTree | any[], options?: NavigationOptions): Promise<boolean> {
    if (typeof(url) === 'string') {
      if (url.indexOf('http') === 0) {
        location.href = url;
        return;
      }
      return this.nav.navigateRoot(this.routerLinkByUrl(url), options);
    } else if (Array.isArray(url)) {
      return this.nav.navigateRoot(this.routerLink(url), options);
    }
    return this.nav.navigateRoot(url, options);
  }

  back(animationOptions?: AnimationOptions): void {
    if (this.storage.history.length < 2) {
      this.navigateBack('/', {replaceUrl: true}).then(() => {})
     } else {
      this.nav.back(animationOptions)
    }
  }

}
