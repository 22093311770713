import { Component, OnDestroy } from '@angular/core';
import { StorageService } from 'src/service/Storage.service';
import { RouterService } from "../../service/Router.service";
import { RequestService } from "../../service/Request.service";
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-car',
  templateUrl: 'car.page.html',
  styleUrls: ['car.page.scss'],
})
export class CarPage implements OnDestroy {

  list = null;
  subscrition = null;

  constructor(
    public requestService: RequestService,
    private storageService: StorageService,
    public routerService: RouterService,
    private router: Router
  ) {
    this.subscrition = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd && e.url == "/car") {
        this.load();
      }
    });
  }

  ngOnDestroy() {
    this.subscrition.unsubscribe();
  }

  load() {
    this.requestService.car.list().subscribe((res) => {
      this.list = res.data;
    })
  }

  add() {
    this.routerService.navigateByUrl('/car/add').then(_ => {});
  }

  remove(id) {
    this.requestService.car.remove(id).subscribe((res) => {
      this.load();
    })
  }

}
