import { NgModule } from '@angular/core';
import { IonContentHideShadow, IonContentScrollStyle } from './content.directive';

@NgModule({
  imports: [
  ],
  declarations: [
    IonContentHideShadow,
    IonContentScrollStyle
  ],
  exports: [
    IonContentHideShadow,
    IonContentScrollStyle
  ]
})
export class DirectiveModule {}
