import { Component, Input } from '@angular/core';
import { StorageService } from '../../service/Storage.service';
import { RequestService } from '../../service/Request.service';
import { environment } from '../../environments/environment';
import { TYPE } from '../../environments/environment.type';

import moment from 'moment';
import { SwiperOptions } from 'swiper';
import { LangService } from '../../service/Lang.service';

@Component({
  selector: 'app-ui-order-info',
  templateUrl: 'order-info.component.html',
  styleUrls: ['order-info.component.scss']
})
export class OrderInfoComponent {

  slide: SwiperOptions = {
    initialSlide: 0,
    speed: 400,
    spaceBetween: 10,
    autoHeight: true,
    pagination: true
  };

  @Input() data: any;

  @Input() createDate: boolean;

  @Input() deadlineDate: boolean;

  @Input() expired: boolean;

  rider = environment.type === TYPE.rider;

  constructor(
    public requestService: RequestService,
    private storageService: StorageService,
    private lang: LangService
  ) {}

  get auth() {
    return this.storageService.auth;
  }

  get weight() {
    return parseFloat(this.data?.weight || 0).toFixed(2);
  }

  get totalNumber() {
    return parseFloat(this.data?.total || 0);
  }

  get total() {
    return this.totalNumber.toFixed(2);
  }

  get deadlineFormat() {
    return moment(this.data.date_deadline).format('YYYY-MM-DD HH:mm:ss');
  }

  get directionDistance() {
    return typeof (this.data.direction_distance) == "string" ? parseFloat(this.data.direction_distance) :
      typeof (this.data.direction_distance) == "number" ? this.data.direction_distance : 0;
  }

  get directionDistanceFixed() {
    return this.directionDistance.toFixed(2);
  }

  get expiredFormat() {
    if (!this.data.date_deadline) { return this.lang.get('无过期时间'); }
    const expired = new Date(this.data.date_deadline).getTime() / 1000;
    const today = (new Date()).getTime() / 1000;
    if (today > expired) { return this.lang.get('已过期'); }
    const diff = expired - today >> 0;
    const days = diff / 60 / 60 / 24 >> 0;
    const hour = diff / 60 / 60 % 24 >> 0;
    const minute = diff / 60 % 60 >> 0;
    return (days > 0 ? days + this.lang.get('天') : '') + (hour > 0 ? hour + this.lang.get('小时') : '') + (minute > 0 ? minute + this.lang.get('分钟') : '');
  }

}
