/**
 * 类型
 */
export enum TYPE {
  // 商家
  merchant,
  // 骑手
  rider
}

/**
 * 配置
 */
export interface Environment {
  /**
   * 生产模式
   * 如果不是生产模式 则访问都经过localhost:9090服务器
   */
  production: boolean;

  /**
   * 网站类型
   */
  type: TYPE;

  /**
   * 接口密钥
   */
  appkey: string;

  /**
   * 接口程序标识
   */
  appid: string;

  /**
   * 服务器
   */
  server: {
    api: string
  }
}
