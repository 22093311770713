import { Component } from '@angular/core';
import { StorageService } from 'src/service/Storage.service';
import { RouterService } from '../../service/Router.service';
import {environment} from '../../environments/environment';
import {TYPE} from '../../environments/environment.type';
import {RequestService} from '../../service/Request.service';
import {ActivatedRoute} from '@angular/router';
import moment from 'moment';
import {AlertService} from '../../service/Alert.service';
import {CardPage} from '../card/card.page';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-order-pay',
  templateUrl: 'order-pay.page.html',
  styleUrls: ['order-pay.page.scss'],
})
export class OrderPayPage {

  rider = environment.type === TYPE.rider;

  setting = null;
  data = null;
  id = null;

  constructor(
    private modalController: ModalController,
    private storageService: StorageService,
    public routerService: RouterService,
    public requestService: RequestService,
    public alertService: AlertService,
    private route: ActivatedRoute
  ) {
    this.load();
  }

  load() {
    this.requestService.setting().subscribe((res) => {
      this.setting = res.data;
      this.id = this.route.snapshot.paramMap.get('id');
      this.requestService.order.find(this.id).subscribe((order) => {
        this.data = order.data;
        if (this.total === 0) {
          this.routerService.navigateRoot('/home/order').then(_ => {});
          return;
        }
      });
    });
  }

  get mode() {
    if (!this.data.payed_deposit) return 1;
    if (!this.data.payed_commission) return 2;
    return 0;
  }

  get total() {
    try {
      if (!this.data.payed_deposit) return parseFloat(this.setting['pay-deposit'] || 0);
      if (!this.data.payed_commission) return parseFloat(this.setting['pay-commission'] || 0);
    } catch {}
    return 0;
  }

  card = null;

  selectCard() {
    CardPage.show(this.modalController, (i) => {
      this.card = i;
    });
  }

  pay() {
    if (!this.card) {
      this.alertService.info('提示', '请选择银行卡').then(_ => {});
      return;
    }
    this.requestService.pay({
      order_id: this.id,
      card_id: this.card.id,
      deposit: this.mode === 1,
      commission: this.mode === 2,
      total: this.total
    }).subscribe(() => {
      this.routerService.navigateRoot('/home/order').then(_ => {});
    });
  }

}
