import { Component } from '@angular/core';
import { StorageService } from 'src/service/Storage.service';
import { RouterService } from "../../service/Router.service";
import {environment} from "../../environments/environment";
import {TYPE} from "../../environments/environment.type";
import {RequestService} from "../../service/Request.service";
import {ActivatedRoute} from "@angular/router";
import moment from "moment";
import {AlertService} from "../../service/Alert.service";
import {FormatAddress} from "../../util/google-map-address";

@Component({
  selector: 'app-order-accept',
  templateUrl: 'order-accept.page.html',
  styleUrls: ['order-accept.page.scss'],
})
export class OrderAcceptPage {

  rider = environment.type === TYPE.rider;

  data = null

  constructor(
    private storageService: StorageService,
    public routerService: RouterService,
    public requestService: RequestService,
    public alertService: AlertService,
    private route: ActivatedRoute
  ) {
    this.load();
  }

  load() {
    const id = this.route.snapshot.paramMap.get('id');
    this.requestService.order.find(id).subscribe((res) => {
      this.data = res.data;
    })
  }

  get auth() {
    return this.storageService.auth;
  }

  get dateDeadline() {
    return this.data?.date_deadline ? moment(this.data.date_deadline).format("YYYY-MM-DD HH:mm:ss") : "未设置";
  }

  get weight() {
    return parseFloat(this.data?.weight || 0).toFixed(2);
  }

  get totalNumber() {
    return parseFloat(this.data?.total || 0);
  }

  get total() {
    return this.totalNumber.toFixed(2);
  }

  get directionDistance() {
    return typeof (this.data.direction_distance) == "string" ? parseFloat(this.data.direction_distance) :
      typeof (this.data.direction_distance) == "number" ? this.data.direction_distance : 0;
  }

  get directionDistanceFixed() {
    return this.directionDistance.toFixed(2);
  }

  get departure_direction_url() {
    const address = this.data?.departure_direction_place?.address_components;
    if (!Array.isArray(address)) return null;
    return "https://maps.google.com/maps?q=" + FormatAddress(address) + "&ie=UTF8&output=embed";
  }

  get receiving_direction_url() {
    const address = this.data?.receiving_direction_place?.address_components;
    if (!Array.isArray(address)) return null;
    return "https://maps.google.com/maps?q=" + FormatAddress(address) + "&ie=UTF8&output=embed";
  }

  confirm() {
    this.requestService.order.accept({ id: this.data.id }).subscribe(() => {
      this.routerService.navigateRoot(['order', 'pay', this.data.id]).then(_ => {});
    });
  }

}
