import { Injectable } from '@angular/core';
import { StorageStatic } from './Storage.static';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  get uuid() {
    return StorageStatic.uuid;
  }
  get auth() {
    return StorageStatic.auth;
  }
  set auth(auth: any) {
    StorageStatic.auth = auth;
  }
  get event() {
    return StorageStatic.event;
  }
  get history() {
    return StorageStatic.history;
  }
  set history(history: any) {
    StorageStatic.history = history;
  }
  get language() {
    return StorageStatic.language;
  }
  set language(data: any) {
    StorageStatic.language = data;
  }
}
