import {Environment, TYPE} from './environment.type';

export const environment: Environment = {
  production: true,
  type: TYPE.merchant,
  appkey: '6c34112b-eed8-458b-a781-13605045db3e',
  appid: 'b3490cca-8edf-4a61-be5f-a9aaa8c6c6a7',
  server: {
    api: 'https://api.pmulogistics.com'
  }
};
