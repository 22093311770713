import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SwiperModule } from 'swiper/angular';

import { PipeModule } from "../pipe/pipe.module";

import { ComponentModule } from '../component/component.module';
import { DirectiveModule } from '../directive/directive.module';

import { LoginPage } from './login/login.page';
import { RegisterPage } from './register/register.page';
import { AboutPage } from './about/about.page';

import { OrderDetailPage } from './order-detail/order-detail.page';
import { OrderAcceptPage } from './order-accept/order-accept.page';
import { OrderPayPage } from './order-pay/order-pay.page';
import { OrderPublishPage } from './order-publish/order-publish.page';
import { OrderSchedulePage } from './order-schedule/order-schedule.page';
import { CarPage } from './car/car.page';
import { CarAddPage } from './car-add/car-add.page';
import { CardPage } from './card/card.page';
import { CardAddPage } from './card-add/card-add.page';

import { UserDetailPage } from './user-detail/user-detail.page';
import { TranslateModule } from '@ngx-translate/core';


const routes: Routes = [
  { path: 'login', component: LoginPage },
  { path: 'register', component: RegisterPage },
  { path: 'about', component: AboutPage },

  { path: 'user/detail', component: UserDetailPage },

  { path: 'order/detail/:id', component: OrderDetailPage },
  { path: 'order/accept/:id', component: OrderAcceptPage },
  { path: 'order/pay/:id', component: OrderPayPage },
  { path: 'order/publish', component: OrderPublishPage },
  { path: 'order/schedule', component: OrderSchedulePage },

  { path: 'car', component: CarPage },
  { path: 'car/add', component: CarAddPage },
  { path: 'card', component: CardPage },
  { path: 'card/add', component: CardAddPage }
];

// 模块
@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ComponentModule,
    DirectiveModule,
    HttpClientModule,

    RouterModule,
    SwiperModule,
    TranslateModule,

    PipeModule
  ],
  declarations: [
    LoginPage,
    RegisterPage,
    AboutPage,

    UserDetailPage,

    OrderDetailPage,
    OrderAcceptPage,
    OrderPayPage,
    OrderPublishPage,
    OrderSchedulePage,

    CarPage,
    CarAddPage,
    CardPage,
    CardAddPage
  ]
})
export class AppModule {
}

// 路由模块
@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AppRouter {
}

// 模块
@NgModule({
  imports: [
    AppModule,
    AppRouter
  ]
})
export class AppRouterModule {
}

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.TabsPageModule)
      },
      {
        path: '',
        loadChildren: () => AppRouterModule
      }
    ], {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      enableTracing: false
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
