import { Component } from '@angular/core';

import { environment } from "../../environments/environment";
import { TYPE } from "../../environments/environment.type";

import { StorageService } from '../../service/Storage.service';
import { RouterService } from "../../service/Router.service";
import { RequestService } from "../../service/Request.service";
import { AlertService } from "../../service/Alert.service";

@Component({
  selector: 'app-register',
  templateUrl: 'register.page.html',
  styleUrls: ['register.page.scss']
})
export class RegisterPage {

  rider = environment.type === TYPE.rider;

  telmode = true;

  username: string = "";
  password: string = "";
  password2: string = "";

  name: string = "";
  description: string = "";
  tel_country: string = "+34";
  tel: string = "";
  email: string = "";

  verify: string = "";

  idCard: string = "";
  driverLicense: string = "";

  licensePlate: string = "";
  loadWeight: number;
  seat: number;

  constructor(
    public requestService: RequestService,
    public alertService: AlertService,
    public storageService: StorageService,
    public routerService: RouterService
  ) {}

  sms() {
    this.requestService.sms.receive(this.tel_country + this.tel).subscribe(() => {
      this.alertService.info("提示", "发送成功").then(_ => {});
    })
  }

  register() {
    this.tel = this.tel.replace(/ /g, "");
    if (this.password != this.password2) {
      this.alertService.info("提示", "两次密码不一致").then(_ => {});
      return;
    }
    if (this.telmode && this.verify?.length < 4) {
      this.alertService.info("提示", "请输入验证码").then(_ => {});
      return;
    }
    this.requestService.base.register({
      username: this.username,
      password: this.password,
      id_card: this.idCard,
      driver_license: this.driverLicense,
      name: this.name,
      description: this.description,
      tel_country: this.tel_country,
      tel: this.tel,
      email: this.email,
      license_plate: this.licensePlate,
      load_weight: this.loadWeight,
      seat: this.seat,
      verify: this.verify
    }).subscribe(async (res) => {
      this.storageService.auth = res.info;
      this.routerService.navigateBack("/home/mine", { replaceUrl: true }).then(_ => {});
    });
  }

}
