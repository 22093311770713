import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';
import { StorageService } from '../service/Storage.service';
import { getLanguageId } from '../service/Storage.static';
import { RequestService } from '../service/Request.service';

import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

declare global {
  interface Navigator {
    app: any;
    device: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  setting = null;

  constructor(
    private router: Router,
    public requestService: RequestService,
    public storageService: StorageService,
    private platform: Platform,
    private location: Location,
    private storage: StorageService,
    private translate: TranslateService,
    private nav: NavController
  ) {
    // 路由历史
    router.events.subscribe(async (e) => {
      if (e instanceof NavigationEnd) {
        const history = this.storageService.history;
        const n: any = this.nav;
        if (history.length > 1 && history[history.length - 2].id === n.lastNavId) {
          history.pop();
        } else {
          history.push({ url: this.router.url, id: n.lastNavId });
        }
      }
      this.storageService.event.emit('router', e);
    });

    // 平台
    this.platform.ready().then(async () => {
      // alert(window.location.href);
      // 验证登录
      this.requestService.base.check().subscribe((res) => {
        if (res.status === 200) {
          this.storageService.auth = res.info;
        }
      });
      // 参数
      const capacitor = this.platform.is('capacitor');
      // const android = this.platform.is('android');
      if (capacitor) {
        // 顶部状态栏
        try {
          await StatusBar.setStyle({style: StatusBarStyle.Light});
          await StatusBar.setBackgroundColor({color: '#FFFFFF'});
          // if (ios) StatusBar.setOverlaysWebView({ overlay: true });
        } catch { }
        // 启动页
        setTimeout(() => {
          SplashScreen.hide();
        }, 800);
      }
      // 加载配置
      this.requestService.setting().subscribe((res) => {
        this.setting = res.data;
        this.initTranslate();
      });
      // 返回键
      document.addEventListener('backbutton', () => {
        if (this.router.url.indexOf('/home/new') !== -1) {
          this.exit();
        } else {
          if (window.history.back) {
            window.history.back();
          } else {
            this.location.back();
          }
        }
      });
    });
  }

  /**
   * 初始化 多语言
   */
  initTranslate() {
    // 之前选择的语言
    let lang = this.storage.language;
    if (lang) {
      lang = getLanguageId(lang);
    }
    // 二级域名
    if (!lang) {
      const childHost = /(\w+)\..+\..+/.exec(location.host);
      if (childHost) {
        lang = getLanguageId(childHost[1]);
      }
    }
    // 强行语言
    if (!lang) {
      lang = this.setting?.force_language;
      if (lang) {
        lang = getLanguageId(lang);
      }
    }
    // 浏览器语言
    if (!lang) {
      lang = this.translate.getBrowserLang();
      if (lang) {
        lang = getLanguageId(lang);
      }
    }
    // 默认语言
    if (!lang) {
      const e: any = environment;
      lang = e.defaultLanguage;
      if (lang) {
        lang = getLanguageId(lang);
      }
    }
    // 默认语言
    if (!lang) {
      lang = this.setting?.default_language;
      if (lang) {
        lang = getLanguageId(lang);
      }
    }
    this.storage.language = lang;
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }

  /**
   * 退出
   */
  exit() {
    if (window.navigator.app) {
      window.navigator.app.exitApp();
    } else if (window.navigator.device) {
      window.navigator.device.exitApp();
    } else {
      window.close();
    }
  }

}
