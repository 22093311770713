import { Component, ViewChild } from '@angular/core';
import { StorageService } from '../../service/Storage.service';
import { RouterService } from '../../service/Router.service';
import {AlertService} from '../../service/Alert.service';
import {environment} from '../../environments/environment';
import {TYPE} from '../../environments/environment.type';
import moment from 'moment';
import { RequestService } from '../../service/Request.service';
import { MapSelector } from 'src/component/map-selector/map-selector';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-order-publish',
  templateUrl: 'order-publish.page.html',
  styleUrls: ['order-publish.page.scss']
})
export class OrderPublishPage {

  constructor(
    public alertService: AlertService,
    public storageService: StorageService,
    public requestService: RequestService,
    public routerService: RouterService,
    public modalController: ModalController
  ) {
    this.createImageUpload();
  }

  @ViewChild('upload') upload;

  rider = environment.type === TYPE.rider;

  dateModalVisible = false;

  images = [];

  title = '';
  remark = '';
  total;
  weight;
  dateDeadlineCache;
  dateDeadline;

  departureDirection = '';
  departureDirectionPlace = '';
  receivingDirection = '';
  receivingDirectionPlace = '';

  createImageUpload() {
    if (!this.images?.length || this.images[this.images.length - 1].file) {
      this.images.push({});
    }
  }

  cleanImage() {
    this.images = [];
    this.createImageUpload();
  }

  imageChange(data, i) {
    i.image = data.image;
    i.file = data.file;
    this.createImageUpload();
  }

  get hasImage(): boolean {
    if (!this.images?.length) return false;
    for (const image of this.images) {
      if (image.file) {
        return true;
      }
    }
    return false;
  }

  publish() {
    this.requestService.order.publish({
      title: this.title,
      remark: this.remark,
      total: this.total,
      weight: this.weight,
      date_deadline: this.dateDeadline,
      departure_direction: this.departureDirection,
      departure_direction_place: this.departureDirectionPlace,
      receiving_direction: this.receivingDirection,
      receiving_direction_place: this.receivingDirectionPlace
    }, {
      departure_direction: !this.rider,
      receiving_direction: !this.rider,
      total: !this.rider,
      weight: !this.rider
    }).subscribe((res) => {
      if (this.hasImage) {
        let count = 0;
        let index = 0;
        const images = [];
        for (const image of this.images) {
          if (!image.file) {continue;}
          count++;
          images.push(index);
          this.requestService.image.upload('/order/' + res.id + '/' + index++, image.file).subscribe(() => {
            count--;
            if (count === 0) {
              this.requestService.order.modify({
                id: res.id,
                images
              }).subscribe(() => {
                this.routerService.navigateBack('/home/order').then(_ => {});
              });
            }
          });
        }
      } else {
        this.routerService.navigateBack('/home/order').then(_ => {});
      }
    });
  }

  selectMap(type) {
    MapSelector.show(this.modalController, {
      success: (place) => {
        if (type === 'departure') {
          this.departureDirection = place.name;
          this.departureDirectionPlace = place;
        } else if (type === 'receiving') {
          this.receivingDirection = place.name;
          this.receivingDirectionPlace = place;
        }
      }
    }).then(() => {});
  }

  selectDate() {
    this.dateDeadlineCache = new Date();
    this.dateModalVisible = true;
  }

  closeDate() {
    this.dateModalVisible = false;
  }

  confirmDate() {
    this.dateDeadline = moment(this.dateDeadlineCache).format('YYYY-MM-DD HH:mm:ss');
    this.dateModalVisible = false;
  }

}
