import { base64Encode, base64Decode } from '../util/base64';
import { uuid } from '../util/uuid';
import EventEmitter from 'events';

export const LANGUAGE = [
  { id: ['en', 'en-US', 'en-EG', 'en-AU', 'en-GB', 'en-CA', 'en-NZ', 'en-IE', 'en-ZA', 'en-JM', 'en-BZ', 'en-TT'],
    icon: 'au', name: '英语', origin: 'English', deepl: 'EN', enable: true },
  { id: 'es', icon: 'es', name: '西班牙语', origin: 'Español', deepl: 'ES', enable: true },
  { id: 'fr', icon: 'fr', name: '法语', origin: 'Français', deepl: 'FR', enable: true },
  { id: ['zh-Hans', 'zh-CN', 'zh'], icon: 'cn', name: '简体中文', origin: '简体中文', deepl: 'ZH', enable: true },
  { id: ['zh-Hant', 'zh-TW', 'zh-HK', 'zh-SG', 'zh'], icon: 'cn', name: '繁体中文', origin: '繁体中文', enable: true },
  { id: 'it', icon: 'it', name: '意大利语', origin: 'Italiano', deepl: 'IT', enable: true },
  { id: 'bg', icon: 'bg', name: '保加利亚语', origin: 'български', deepl: 'BG', enable: true },
  { id: 'cs', icon: 'cs', name: '捷克语', origin: 'čeština', deepl: 'CS', enable: true  },
  { id: 'de', icon: 'de', name: '德语', origin: 'Deutsche', deepl: 'DE', enable: true  },
  { id: 'da', icon: 'dk', name: '丹麦语', origin: 'Dansk', deepl: 'DA', enable: true },
  { id: 'el', icon: 'gr', name: '希腊语', origin: 'Ελληνικά', deepl: 'EL', enable: true },
  { id: 'et', icon: 'et', name: '爱沙尼亚语', origin: 'Eestlane', deepl: 'ET', enable: true },
  { id: 'fi', icon: 'fi', name: '芬兰语', origin: 'Suomalainen', deepl: 'FI', enable: true },
  { id: 'hr', icon: 'hr', name: '克罗地亚语', origin: 'Hrvatski', enable: true },
  { id: 'hu', icon: 'hu', name: '匈牙利语', origin: 'Magyar', deepl: 'HU', enable: true },
  { id: 'ja', icon: 'jp', name: '日语', origin: '日本語', deepl: 'JA', enable: true },
  { id: 'ko', icon: 'kr', name: '韩语', origin: '한국어', enable: true },
  { id: 'lt', icon: 'lt', name: '立陶宛语', origin: 'Lietuvis', deepl: 'LT', enable: true },
  { id: 'lv', icon: 'lv', name: '拉脱维亚语', origin: 'Latvietis', deepl: 'LV', enable: true },
  { id: 'mt', icon: 'mt', name: '马耳他语', origin: 'Malti', enable: true },
  { id: 'nl', icon: 'sd', name: '荷兰语', origin: 'Nederlands', deepl: 'NL', enable: true },
  { id: 'no', icon: 'bv', name: '挪威语', origin: 'Norsk', enable: true },
  { id: 'pl', icon: 'pl', name: '波兰语', origin: 'Polskie', deepl: 'PL', enable: true },
  { id: 'pt', icon: 'pt', name: '葡萄牙语', origin: 'Português', deepl: 'PT', enable: true },
  { id: 'ro', icon: 'ro', name: '罗马尼亚语', origin: 'Română', deepl: 'RO', enable: true },
  { id: 'ru', icon: 'ru', name: '俄语', origin: 'Руская', deepl: 'RU', enable: true },
  { id: 'sk', icon: 'sk', name: '斯洛伐克语', origin: 'Slovák', deepl: 'SK', enable: true },
  { id: 'sl', icon: 'sl', name: '斯洛文尼亚语', origin: 'Slovenščina', deepl: 'SL', enable: true },
  { id: 'sv', icon: 'sv', name: '瑞典语', origin: 'svenska', deepl: 'SV', enable: true },
  { id: 'tr', icon: 'tr', name: '土耳其语', origin: 'Türk', enable: true },
];

const LANGUAGE_LIST = ['zh-Hans', 'en', 'es'];
for (const i of LANGUAGE) {
  let has = false;
  for (const e of LANGUAGE_LIST) {
    if (i.id === e || i.id.indexOf(e) !== -1) {
      has = true;
      break;
    }
  }
  i.enable = has;
}

export const getLanguage = (id: string): any => {
  for (const i of LANGUAGE) {
    if (typeof(i.id) === 'string' && i.id === id) {
      return i;
    } else if (Array.isArray(i.id)) {
      for (const y of i.id) {
        if (typeof(y) === 'string' && y === id) {
          return i;
        }
      }
    }
  }
  return null;
};

export const getLanguageId = (id: string): string => {
  const lang = getLanguage(id);
  if (lang) { return Array.isArray(lang.id) ? lang.id[0] : lang; }
  return '';
};

const store = {
  // 设备唯一码
  uuid: null,
  // 登录权限
  auth: null,
  // 事件池
  event: new EventEmitter(),
  // 路由历史
  history: [],
  // 语言
  language: null
};

class Storage {
  constructor(private storage: globalThis.Storage) {}
  /**
   * 获取
   */
  get(key: string): { updateTime: number; data: any } | null {
    const cache = this.storage.getItem(key);
    if (!cache) { return null; }
    let result = null;
    try {
      result = JSON.parse(cache);
    } catch (e) { }
    return result;
  }
  /**
   * 保存
   */
  set(key: string, value: any) {
    this.storage.setItem(key, JSON.stringify({
      data: value,
      updateTime: Date.now()
    }));
  }
  /**
   * 解密
   */
  decrypt(key: string): { updateTime: number; data: any } | null {
    const cache = this.storage.getItem(key);
    if (!cache) { return null; }
    let result = null;
    try {
      result = base64Decode(cache);
      result = JSON.parse(result);
    } catch (e) { }
    return result;
  }
  /**
   * 加密
   */
  encrypt(key: string, value: any) {
    this.storage.setItem(key, base64Encode(JSON.stringify({
      data: value,
      updateTime: Date.now()
    })));
  }
  /**
   * 删除
   */
  remove(key: string) {
    this.storage.removeItem(key);
  }
}

export const LOCAL = new Storage(localStorage);
export const SESSION = new Storage(sessionStorage);

export class StorageStatic {
  /**
   * 初始化
   */
  static init() {
    store.auth = LOCAL.decrypt('auth')?.data;
    store.uuid = LOCAL.decrypt('uuid')?.data;
    if (!store.uuid) {
      store.uuid = uuid();
      LOCAL.encrypt('uuid', store.uuid);
    }
  }
  /**
   * 设备唯一码
   */
  static get uuid() {
    return store.uuid;
  }
  /**
   * 权限
   */
  static get auth() {
    return store.auth;
  }
  static set auth(auth: any) {
    if (!auth) {
      LOCAL.remove('auth');
      store.auth = null;
      return;
    }
    LOCAL.encrypt('auth', auth);
    store.auth = auth;
  }
  /**
   * 事件池
   */
  static get event() {
    return store.event;
  }
  /**
   * 路由历史
   */
  static get history() {
    return store.history;
  }
  static set history(history: any) {
    store.history = history;
  }
  static get language() {
    const key = 'language';
    if (!store.language) { store.language = LOCAL.decrypt(key)?.data || null; }
    return store.language;
  }
  static set language(data: any) {
    const key = 'language';
    if (!store.language) { store.language = LOCAL.decrypt(key)?.data || null; }
    store.language = data;
    LOCAL.encrypt(key, store.language);
  }
}
