import { Component } from '@angular/core';
import { StorageService } from '../../service/Storage.service';
import { RouterService } from "../../service/Router.service";
import { RequestService } from "../../service/Request.service";

@Component({
  selector: 'app-car-add',
  templateUrl: 'car-add.page.html',
  styleUrls: ['car-add.page.scss']
})
export class CarAddPage {

  constructor(
    public storageService: StorageService,
    public requestService: RequestService,
    public routerService: RouterService
  ) {}

  name: string = "";
  description: string = "";
  licensePlate: string = "";
  loadWeight: number;
  seat: number;

  add() {
    this.requestService.car.add({
      name: this.name,
      description: this.description,
      license_plate: this.licensePlate,
      load_weight: this.loadWeight,
      seat: this.seat
    }).subscribe(() => {
      this.routerService.navigateBack('/car').then(_ => {});
    });
  }

}
