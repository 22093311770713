import { Component, ElementRef, ViewChild, Input } from '@angular/core';
import { StorageService } from '../../service/Storage.service';
import { RouterService } from '../../service/Router.service';
import { RequestService } from '../../service/Request.service';
import { AlertService } from '../../service/Alert.service';
import { LangService } from '../../service/Lang.service';
import { ModalController } from '@ionic/angular';

// const baseUrl = 'https://merchant.pmulogistics.com/assets/google-map-selector.html';
const baseUrl = 'assets/google-map-selector.html';

@Component({
  selector: 'app-map-selector',
  templateUrl: 'map-selector.html',
  styleUrls: ['map-selector.scss']
})
export class MapSelector {

  setting = null;
  contentWindow = null;
  direction = null;

  constructor(
    public storageService: StorageService,
    public requestService: RequestService,
    public alertService: AlertService,
    public lang: LangService,
    public routerService: RouterService,
    public modalController: ModalController
  ) {}

  static async show(modalController: ModalController, props: {
    success: (place) => void;
  }) {
    const modal = await modalController.create({
      component: MapSelector,
      cssClass: 'app-modal-map-selector',
      componentProps: props
    });
    return await modal.present();
  }

  @Input() success;

  async close() {
    await this.modalController.dismiss();
  }

  confirm() {
    this.success(this.direction);
    this.close().then(() => {});
  }

  @ViewChild('iframe') iframe: ElementRef<HTMLInputElement>;

  sendData(type, data) {
    // this.iframe.nativeElement.src = baseUrl + '#' + encodeURIComponent(JSON.stringify({ type, data }));
    this.contentWindow.postMessage({ type, data }, '*');
  }

  message(event) {
    console.log(1);
    if (event.data?.id !== 'map-selector') return;
    if (event.data?.type === 'log') {
      console.log(event.data?.data);
    } else if (event.data?.type === 'init') {
      this.contentWindow = event.source;
      this.sendData('init', { key: this.setting['google-map-api-key'], language: this.storageService.language });
    } else if (event.data?.type === 'error') {
      const e = event.data?.data;
      if (e.code === 1) {
        this.alertService.info('提示', this.lang.get(e.message) + ': ' + e.data).then(_ => {});
      }
    } else if (event.data?.type === 'language') {
      const data = event.data?.data;
      const temp = {};
      for (const i in data) {
        if (data.hasOwnProperty(i)) {
          temp[i] = this.lang.get(data[i]);
        }
      }
      this.sendData('language', temp);
    } else if (event.data?.type === 'success') {
      this.direction = event.data?.data;
    }
  }

  messageHandle = null;

  ionViewDidEnter() {
    this.messageHandle = this.message.bind(this);
    window.addEventListener('message', this.messageHandle, false);
    // 加载配置
    this.requestService.setting().subscribe((res) => {
      this.setting = res.data;
      this.iframe.nativeElement.src = baseUrl;
    });
  }

  ionViewDidLeave() {
    this.iframe.nativeElement.src = "about:blank";
    window.removeEventListener('message', this.messageHandle, false);
    this.messageHandle = null;
  }
}
