import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { SwiperModule } from 'swiper/angular';

import { TranslateModule } from '@ngx-translate/core';

import { AvatarComponent } from './avatar/avatar.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { OrderInfoComponent } from './order-info/order-info.component';
import { UploadComponent } from './upload/upload.component';

import { MapSelector } from './map-selector/map-selector';
import { MapSchedule } from './map-schedule/map-schedule';

import { PipeModule } from '../pipe/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SwiperModule,
    TranslateModule,
    PipeModule
  ],
  declarations: [
    AvatarComponent,
    UserInfoComponent,
    OrderInfoComponent,
    UploadComponent,

    MapSelector,
    MapSchedule
  ],
  exports: [
    AvatarComponent,
    UserInfoComponent,
    OrderInfoComponent,
    UploadComponent,

    MapSelector,
    MapSchedule
  ],
  providers: [
  ]
})
export class ComponentModule { }
