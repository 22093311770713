import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ui-avatar',
  templateUrl: 'avatar.component.html',
  styleUrls: ['avatar.component.scss']
})
export class AvatarComponent {

  @Input() src: string;

  @Input() size: string;

  emptySrc = 'assets/image/user-gray.svg';

  constructor() {}

}
